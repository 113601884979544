'use client';

import { useMemo } from 'react';

import type { StockProps } from './Stock.types';
import { getStockStatus } from './Stock.utils';

export function useStockStatus({ item, type }: StockProps) {
  const status = useMemo(() => {
    if (!item) return;
    if (type === 'pdp') {
      return getStockStatus({
        type,
        item,
      });
    }
    if (type === 'plp') {
      return getStockStatus({
        type,
        item,
      });
    }
    if (type === 'cart' || type === 'checkout') {
      return getStockStatus({
        type,
        item,
      });
    }
    return;
  }, [item, type]);

  return status;
}
