import Paragraph from '@ui/components/Paragraph/Paragraph';
import { cn } from '@ngg/storefront-utils';

import type { UNKNOWN } from '../Stock.types';
import { type StockStatusProps } from '../Stock.types';
import StockIndicator from './StockIndicator';

export default function Unknown({
  status,
  config,
}: {
  status: UNKNOWN;
  config: StockStatusProps;
}) {
  const { type, item, className, stockInfo, dictionary, ...props } = config;
  if (type === 'pdp') {
    return (
      <div className={cn('text-sm', className)} {...props}>
        <div className="inline-flex items-start gap-2">
          <StockIndicator status={status} />
          <Paragraph className="inline-block font-bold">
            {dictionary?.unknown ?? 'Unknown stock status'}
          </Paragraph>
        </div>
      </div>
    );
  }
  return null;
}
