import { tv } from 'tailwind-variants';

import { type StockStatus, StockStatusEnum } from '../Stock.types';
import { CheckIcon } from '@ngg/icons';

export const stockStatusVariant = tv({
  base: 'inline-flex h-3 w-3 shrink-0 items-center justify-center self-center rounded-full',
  variants: {
    code: {
      [StockStatusEnum.UNKNOWN]: 'bg-yellow-600 text-transparent',
      [StockStatusEnum.IN_STOCK_ITEM]: 'bg-green-600 text-white',
      [StockStatusEnum.DELIVERY_ITEM]: 'bg-green-600 text-transparent',
      [StockStatusEnum.INCOMING_ITEM]: 'bg-green-600 text-white',
      [StockStatusEnum.SOLD_OUT_ITEM]: 'hidden bg-red-600 text-transparent',
      [StockStatusEnum.OUT_OF_STOCK_ITEM]: 'hidden bg-red-600 text-transparent',
      [StockStatusEnum.LIMITED_STOCK_ITEM]: 'bg-green-600 text-white',
    },
  },
});

export default function StockIndicator({ status }: { status: StockStatus }) {
  return (
    <div role="status" className={stockStatusVariant(status)}>
      <CheckIcon strokeWidth={4} className="h-2 w-2 text-inherit" />
    </div>
  );
}
