import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { cn } from '@ngg/storefront-utils';
import type { Node } from '@contentful/rich-text-types';
import type { HTMLAttributes, ReactNode } from 'react';
import Link from '../Link/Link';
import { Links } from '@ts/contentful/richText';
import Paragraph from '../Paragraph/Paragraph';
import { Heading } from '../Heading/Heading';
import ClientFriendlyEntry from '../blocks/Entry/ClientFriendlyEntry';
import RichTextLink from '../RichTextLink/RichTextLink';
import Frame from '../Frame/Frame';

export function parseOptions({
  content,
  client = true,
  Component = ClientFriendlyEntry,
  onClick,
  options,
  locale,
  market,
}: {
  market?: string;
  locale?: string;
  content: {
    links: Links;
  };
  client?: boolean;
  onClick?: () => void;
  Component?: (props?: any) => JSX.Element | null;
  options?: {
    renderMark: Record<
      keyof BLOCKS,
      (props: any, children: ReactNode) => ReactNode
    >;
    renderNode: Record<
      keyof BLOCKS,
      (props: any, children: ReactNode) => ReactNode
    >;
  };
}) {
  const entryMap = new Map();

  if (content?.links?.entries?.block) {
    content?.links.entries.block.forEach((entry) => {
      if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
    });
  }
  if (content?.links?.entries?.inline) {
    content?.links.entries?.inline.forEach((entry) => {
      if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
    });
  }

  if (content?.links?.assets?.block) {
    content?.links.assets?.block.forEach((entry) => {
      if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
    });
  }

  if (content?.links?.entries?.hyperlink) {
    content?.links.entries.hyperlink.forEach((entry) => {
      if (entry?.sys?.id) entryMap.set(entry.sys.id, entry);
    });
  }

  // TODO: Add more types of nodes
  return {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data }: Node, children: ReactNode) => {
        return (
          <RichTextLink locale={locale} href={data.uri}>
            {children}
          </RichTextLink>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: ({ data }: Node, children: ReactNode) => {
        const entry = entryMap.get(data.target.sys.id);

        if (entry && entry.slug) {
          return (
            <Link className="underline" href={entry.slug}>
              {children}
            </Link>
          );
        }
        return (
          <button onClick={onClick} type="button" className="underline">
            {children}
          </button>
        );
      },
      [INLINES.EMBEDDED_ENTRY]: ({ data }: Node) => {
        const entry = entryMap.get(data.target.sys.id);
        if (entry)
          return (
            <Component
              client={client}
              {...entry}
              locale={locale}
              market={market}
            />
          );
        return null;
      },
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }: Node) => {
        const entry = entryMap.get(data.target.sys.id);
        if (entry)
          return (
            <Component
              client={client}
              {...entry}
              locale={locale}
              market={market}
            />
          );
        return null;
      },
      [BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode) => {
        if (Array.isArray(children)) {
          const [content] = children;
          if (Array.isArray(content)) {
            const [, text] = content;
            if (text.startsWith('<iframe')) {
              return <Frame text={text} />;
            }
          }
        }
        return <Paragraph className="leading-relaxed">{children}</Paragraph>;
      },
      [BLOCKS.HEADING_2]: (node: Node, children: ReactNode) => {
        return (
          <Heading
            as="h2"
            className="mb-0 text-3xl leading-tight text-inherit md:text-6xl">
            {children}
          </Heading>
        );
      },
      [BLOCKS.HEADING_3]: (node: Node, children: ReactNode) => {
        return (
          <Heading
            as="h3"
            className="mb-3 font-sans text-[24px] normal-case text-black">
            {children}
          </Heading>
        );
      },
      [BLOCKS.HEADING_4]: (node: Node, children: ReactNode) => {
        return (
          <Heading as="h4" className="text-[24px] md:text-[32px]">
            {children}
          </Heading>
        );
      },
      [BLOCKS.HEADING_5]: (node: Node, children: ReactNode) => {
        return (
          <Heading
            as="h5"
            className="md:mt-6xl mb-4 mt-[24px] text-[18px] text-inherit md:text-xl">
            {children}
          </Heading>
        );
      },
      ...options?.renderNode,
    },
    renderText: (text: string) => {
      if (!text.length) return null;
      return text
        .split('\n')
        .flatMap((t, i) => [i > 0 && <br key={`line-${i}`} />, t]);
    },
  };
}

export default function RichText({
  content,
  client,
  options,
  className,
  onClick,
  locale,
  market,
  ...props
}: Omit<HTMLAttributes<Element>, 'content'> & {
  content: any;
  client?: boolean;
  options?: any;
  className?: string;
  locale?: string | undefined;
  market?: string;
  onClick?: () => void;
}) {
  const opts = parseOptions({
    content,
    client,
    options,
    onClick,
    locale,
    market,
  });
  const text = documentToReactComponents(content?.json, opts);
  return (
    <div
      className={cn(!className?.includes('not-prose') && 'prose', className)}
      {...props}>
      {text}
    </div>
  );
}
